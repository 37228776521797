import { Link } from 'react-router-dom'

export default function Navbar() {
	return (
		<nav>
			<Link to="/"><img className='logo' alt='nextStepLogo' src="./NextStepLogo.png"/></Link>
			<div className="hamburger-menu">
    			<input id="menutoggle" type="checkbox"  />
    			<label className="menubtn" htmlFor="menutoggle">
      				<span></span>
					  
    			</label>
				
    			<ul className="menubox">
					<a href="/" className='menuitem'>
						Home
					</a>

					<a href="/Price" className='menuitem'>
						Contact & Pricing
					</a>
{/* 
					<a href="/Contact" className='menuitem'>
						Contact Us
					</a> */}
					<br/>
					
    			</ul>
  			</div>
		</nav>
	)
}